/* eslint-disable unused-imports/no-unused-vars */
// eslint-disable-next-line import/no-extraneous-dependencies
import 'keen-slider/keen-slider.min.css';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useKeenSlider } from 'keen-slider/react';
import React from 'react';

export default function ImageSection2({
  sectionData,
  data,
}: {
  data: any;
  sectionData: any;
}) {
  const animation = { duration: 20000, easing: (t: any) => t };
  const [sliderRef] = useKeenSlider({
    slides: {
      perView: 'auto',
      spacing: 10,
    },
    mode: 'free-snap',
    renderMode: 'performance',
    loop: true,
    drag: true,
    created(s) {
      s.moveToIdx(5, true, animation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, animation);
    },
  });

  return (
    <div className="grid w-full">
      <div ref={sliderRef} className="keen-slider">
        {sectionData.content.map(
          (galleryData: any, i: any) =>
            galleryData.image && (
              <div key={i} className="aspect-square h-[100px]">
                <img
                  className="keen-slider__slide h-full w-auto object-cover"
                  src={galleryData.image.url}
                  style={{
                    borderRadius: `12px`,
                    // width: 'auto', // Allow the width to adjust based on the original aspect ratio
                    // maxHeight: '140px', // Set the maximum height
                  }}
                  alt="gallery"
                />
              </div>
            ),
        )}
      </div>
    </div>
  );
}
